module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-4XW8BXM5C0"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Wulkanizacja KostaAuto","short_name":"KostaAuto","description":"Wulkanizacja Tychy KostaAuto - Wymiana opon w Tychach.","lang":"pl","start_url":"/","background_color":"#f7f0eb","theme_color":"#099BF8","display":"standalone","icon":"src/images/favicon.jpg","icon_options":{"purpose":"maskable"},"cache_busting_mode":"none","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/kontakt","/kalkulator-opon"]},
    }]
